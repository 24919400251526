<template>
  <div v-if="authUserIsCoach" class="container-xxl">
    <div class="row gx-3">
      <div class="col-12 col-md-9">
        <ClientList class="my-3"></ClientList>
      </div>
      <div class="col-12 col-md-3">
        <CoachSidebar></CoachSidebar>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Clients',
  components: {
    ClientList: () => import('@/controllers/client/ClientList'),
    CoachSidebar: () => import('@/components/dashboard/CoachSidebar'),
  },
  computed: {
    ...mapGetters('auth', ['authUserIsCoach']),
  },
};
</script>
